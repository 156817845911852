// src/components/FriendsList/FriendsList.module.sass

.friends_list
    display: flex
    flex-direction: column
    align-items: center
    align-self: stretch
    gap: 16px
    flex: 1

.header
    display: flex
    justify-content: space-between
    align-items: center
    align-self: stretch
    width: 100%

.heading_friends
    color: var(--color-white)
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: normal

.total_tokens
    color: var(--color-gray)
    text-align: right
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal

.friends
    display: flex
    flex-direction: column
    align-items: center
    align-self: stretch
    gap: 28px
    flex: 1
    .friend
        align-self: stretch
        display: flex
        align-items: center
        gap: 16px
        .avatar
            width: 40px
            height: 40px
            border-radius: 50%
            object-fit: cover
            flex: none
        .name
            flex: 1
            color: var(--color-white)
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: normal
        .tokens
            color: var(--color-white)
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: normal
