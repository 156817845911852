// src/components/Button/PrimaryButton.module.sass

.primary_button
    border: none
    background: none
    flex: 1
    min-width: 120px
    min-height: 50px
    max-height: 50px
    padding: 0 20px
    border-radius: 8px
    cursor: pointer
    transition: 0.3s ease

    display: flex
    justify-content: center
    align-items: center

    font-size: 18px
    font-style: normal
    font-weight: 400
    line-height: normal

    &:hover
        opacity: 0.9
        animation: scale_bounce 0.3s ease
    &:active
        opacity: 0.7
        transform: scale(0.95)

    &.disabled
        background-color: #6e6e6e
        cursor: not-allowed

    &.primary
        background-color: var(--color-black)
        color: var(--color-gray)
        &.min_font
            font-size: 14px

    &.primary_border
        background-color: var(--color-black)
        color: var(--color-white)
        border: 1px solid var(--color-gray)

    &.secondary
        background-color: #808080
        color: var(--color-white)

    &.secondary_green
        background-color: #008000
        color: var(--color-white)
