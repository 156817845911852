// src/components/PlayForFree/PlayForFree.module.scss

.play_for_free_wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    align-self: stretch
    gap: 24px
    padding: 20px
    border-radius: 8px
    background-color: var(--color-white10)
    border: none
    transition: 0.3s ease
    animation: fade_in_wrapper 0.3s ease
    &:active
        transform: scale(0.95)
    img
        width: 100%
        height: 220px
        object-fit: cover
        border-radius: 5px

@keyframes fade_in_wrapper
    0%
        opacity: 0
        transform: scale(0.8)
    60%
        opacity: 0.7
        transform: scale(1.03)
    100%
        opacity: 1
        transform: scale(1)

.details_card
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    align-self: stretch
    gap: 16px

.title_card
    color: var(--color-white)
    text-align: center
    font-size: 22px
    font-style: normal
    font-weight: 400
    line-height: 120%

.text_card
    color: var(--color-gray)
    text-align: center
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 120%
    width: 100%
    max-width: 276px
