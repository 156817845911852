
// src/components/Leaderboard/Leaderboard.module.sass

.leaderboard
    display: flex
    flex-direction: column
    align-items: center
    align-self: stretch
    gap: 32px

.header
    display: flex
    justify-content: space-between
    align-items: center
    align-self: stretch
    width: 100%

.heading_tasks
    color: var(--color-white)
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: normal

.total_participants
    color: var(--color-gray)
    text-align: right
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal

.participant_list
    display: flex
    flex-direction: column
    align-items: center
    align-self: stretch
    gap: 28px
    flex: 1

.participant
    display: flex
    align-items: center
    align-self: stretch
    gap: 12px

.avatar
    width: 40px
    height: 40px
    border-radius: 50%
    object-fit: cover
    flex: none

.info
    flex: 1
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 8px
    .name
        color: var(--color-white)
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: normal
    .score
        color: var(--color-gray)
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: normal

.position
    color: var(--color-white)
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: normal
    flex: none
    width: 40px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
