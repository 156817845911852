// src/components/TaskModal/TaskModal.module.sass

.overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: var(--color-black60)
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: center
    z-index: 1000
    overflow: hidden
    animation: fadeIn 0.3s ease

@keyframes fadeIn
    0%
        background: rgba(0, 0, 0, 0)
    100%
        background-color: var(--color-black60)

.modal
    background-color: var(--color-grayModal)
    padding: 40px
    border-radius: 30px 30px 0 0
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    animation: modalFadeIn 0.8s ease-in-out
    transform-origin: bottom

@keyframes modalFadeIn
    0%
        transform: translateY(100%) scaleY(0.2)
    60%
        transform: translateY(0%) scaleY(1.1)
    100%
        transform: translateY(0%) scaleY(1)

.closeButton
    position: absolute
    top: 16px
    right: 16px
    background: none
    border: none
    cursor: pointer
    background-image: url("data:image/svg+xml,%3Csvg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5 27C21.4036 27 27 21.4033 27 14.5C27 7.59668 21.4036 2 14.5 2C7.59644 2 2 7.59668 2 14.5C2 21.4033 7.59644 27 14.5 27ZM14.5 29C22.5081 29 29 22.5083 29 14.5C29 6.4917 22.5081 0 14.5 0C6.49188 0 0 6.4917 0 14.5C0 22.5083 6.49188 29 14.5 29ZM8 19.6069L9.39288 21L14.5 15.8931L19.6071 21L21 19.6069L15.8929 14.5L20.9999 9.39307L19.6071 8L14.5001 13.1069L9.39294 8L8.00006 9.39307L13.1072 14.5L8 19.6069Z' fill='black'/%3E%3C/svg%3E%0A")
    background-repeat: no-repeat
    background-size: cover
    width: 24px
    height: 24px

.icon
    width: 80px
    height: 80px
    margin-bottom: 26px
    object-fit: cover
    border-radius: 50%
    overflow: hidden

.title
    color: var(--color-white)
    font-size: 28px
    font-style: normal
    font-weight: 400
    line-height: normal
    text-align: center
    margin-bottom: 20px

.description
    color: var(--color-gray)
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    text-align: center
    margin-bottom: 16px

.reward
    margin: 32px 0
    color: var(--color-white)
    text-align: center
    font-size: 22px
    font-style: normal
    font-weight: 400
    line-height: normal

.checkDescription
    color: var(--color-gray)
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    text-align: center
    margin-bottom: 16px
