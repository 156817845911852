// src/components/ProfileCard/ProfileCard.module.sass

.profileCard
    background: var(--color-grayProfile)
    border-radius: 8px
    width: 100%
    display: flex
    flex-direction: column

.profileCardContent
    display: flex
    align-items: flex-start
    padding: 20px 16px
    gap: 12px
    align-self: stretch

.tabs
    display: flex
    .tabButton
        flex: 1
        display: flex
        justify-content: center
        align-items: center
        padding: 8px 0
        height: 40px
        border-radius: 8px
        border: none
        background: none
        color: var(--color-white)
        position: relative
        overflow: hidden
        &:nth-child(2)
            background: var(--color-darkGreen)
        &::before
            content: ""
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            height: 100%
            background: var(--color-black)
            opacity: 0.6
            z-index: 1
            transition: opacity 0.3s
        &.active
            &::before
                opacity: 0
.avatar
    width: 40px
    height: 40px
    border-radius: 50%
    object-fit: cover

.info
    flex: 1
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 4px
    .username
        color: var(--color-white)
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: normal
    .details
        color: var(--color-gray)
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: normal

.ranking
    .rankingTitle
        color: var(--color-white)
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: normal
    .rank
        color: var(--color-gray)
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: normal
