// src/components/PlayForFree/PlayForFree.module.scss

.play_by_betting_wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    align-self: stretch
    gap: 24px
    padding: 20px
    border-radius: 8px
    background-color: var(--color-darkGreen30)
    border: none
    animation: fade_in_wrapper 0.3s ease forwards 0.2s
    opacity: 0
    transform: scale(0.8)
    &:active
        transform: scale(0.95)
    &.active
        img
            height: 220px
    img
        width: 100%
        height: 156px
        border-radius: 5px
        object-fit: cover
        transition: 0.3s ease

@keyframes fade_in_wrapper
    0%
        opacity: 0
        transform: scale(0.8)
    60%
        opacity: 0.7
        transform: scale(1.03)
    100%
        opacity: 1
        transform: scale(1)

.details_card
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    align-self: stretch
    gap: 12px

.title_card
    color: var(--color-lightGreen)
    text-align: center
    font-size: 22px
    font-style: normal
    font-weight: 400
    line-height: 120%

.hide_details
    display: none
    flex-direction: column
    justify-content: center
    align-items: center
    align-self: stretch
    gap: 16px
    &.active
        display: flex

.text_card
    color: var(--color-white)
    text-align: center
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 120%
    span
        color: var(--color-lightGreen)
