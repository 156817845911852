// src/pages/Friends/Friends.module.sass

.friends_page_wrapper
    width: 100%
    height: calc( 100% - 64px )
    overflow: auto
    display: flex
    flex-direction: column
    position: relative
    padding-bottom: 70px

.friends_list_wrapper
    flex: 1
    flex-direction: column
    display: flex
    padding: 20px
    animation: fade_in_task_list 0.3s ease

@keyframes fade_in_task_list
    0%
        opacity: 0
    100%
        opacity: 1

.inv_button
    padding: 10px 20px
    width: 100%
    display: flex
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)
    position: fixed
    bottom: 63px

.inv_button_styles
    width: 100%
