// src/components/Navigation/Navigation.module.scss

.navigation
    display: flex
    align-items: center
    justify-content: center
    gap: 8px
    background-color: var(--color-black)
    width: 100%
    padding: 0 16px
    height: 64px
    flex: none
    position: relative
    z-index: 100
    a
        display: flex
        flex: 1
        justify-content: center
        align-items: center
        flex-direction: column
        opacity: 0.6
        padding: 8px 5px 12px 5px
        gap: 6px
        &.active
            opacity: 1
            animation: bounce_nav_size 0.4s ease
        p
            color: var(--color-white)
            text-align: center
            font-size: 11px
            font-style: normal
            font-weight: 400
            line-height: normal

@keyframes bounce_nav_size
    0%
        transform: scale(1)
        opacity: 0.6
    40%
        transform: scale(0.8)
        opacity: 0.4
    100%
        transform: scale(1)
        opacity: 1
