// src/components/ConnectWallet/ConnectWallet.module.sass

.connect_wallet_wrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 44px
    background: var(--color-black)
    position: fixed
    inset: 0
    animation: fade_in_wrapper 0.3s ease

@keyframes fade_in_wrapper
    0%
        opacity: 0
        transform: scale(0.8)
    100%
        opacity: 1
        transform: scale(1)

.character_image
    max-width: 320px
    width: 100%
    height: auto
    margin-bottom: 20px

.skip_button
    color: var(--color-white)
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    text-align: center
    cursor: pointer
    margin-top: 16px
    opacity: 0.7
    border: none
    background: none

.title
    color: var(--color-white)
    text-align: center
    font-size: 28px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 16px

.description
    color: var(--color-gray)
    text-align: center
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 32px
