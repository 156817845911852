@import url('https://fonts.googleapis.com/css2?family=Lacquer&display=swap')

*
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: "Lacquer", system-ui
    font-weight: 400
    font-style: normal

html, body
    width: 100%
    height: 100%
    background: var(--color-black)

.min_font
    font-size: 14px

.max_width
    width: 100%
    max-width: 100%

body
    overflow: hidden

#root
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    overflow: hidden

img
    max-width: 100%
    height: auto

a
    text-decoration: none
    color: inherit

ul, ol
    list-style: none

button, a
    -webkit-tap-highlight-color: transparent

.page_header
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 24px
    padding: 32px 16px
    &.min
        gap: 12px
        padding: 32px 16px 16px 16px
    .page_header_title
        color: var(--color-white)
        text-align: center
        font-size: 28px
        font-style: normal
        font-weight: 400
        line-height: normal
        opacity: 0
        transform: scale(0.8)
        animation: fade_in_page_header 0.3s ease forwards 0s
    .page_header_description
        width: 100%
        max-width: 250px
        color: var(--color-gray)
        text-align: center
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: normal
        opacity: 0
        transform: scale(0.8)
        animation: fade_in_page_header 0.3s ease forwards 0.2s

@keyframes fade_in_page_header
    0%
        opacity: 0
        transform: scale(0.8)
    60%
        opacity: 0.7
        transform: scale(1.03)
    100%
        opacity: 1
        transform: scale(1)
