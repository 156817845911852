// src/pages/Leaderboard/Leaderboard.module.sass

.leaderboard_page_wrapper
    width: 100%
    height: calc( 100% - 64px )
    overflow: auto
    display: flex
    flex-direction: column
    position: relative

.card_profile_wrapper
    display: flex
    flex-direction: column
    align-items: center
    padding: 0 20px
    animation: fade_in_task_list 0.3s ease
    width: 100%

.leaderboard_list_wrapper
    flex: 1
    flex-direction: column
    display: flex
    padding: 20px
    animation: fade_in_task_list 0.3s ease forwards 0.2s
    opacity: 0
    width: 100%

@keyframes fade_in_task_list
    0%
        opacity: 0
    100%
        opacity: 1

.buttons_wrapper
    display: flex
    justify-content: space-between
    gap: 16px
    width: 100%
    padding: 20px 20px 0 20px
