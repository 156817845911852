// src/pages/Home/Home.module.sass

.home_page_wrapper
    width: 100%
    overflow: auto
    padding: 16px 20px
    color: var(--color-white)
    height: calc( 100% - 64px )
    display: flex
    flex-direction: column
    gap: 12px
