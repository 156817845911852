.splash_screen
    background: url('../../assets/images/splash_screen.jpg')
    background-position: center !important
    background-size: cover !important
    width: 100%
    height: 100vh
    position: relative
    padding: 156px 0 0 0
    flex-direction: column
    align-items: center
    justify-content: flex-start
    isolation: isolate
    &::before
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: url('../../assets/images/splash_screen_b.jpg')
        background-position: center !important
        background-size: cover !important
        z-index: -1
        animation: bg_blink 0.6s steps(2) infinite

@keyframes bg_blink
    0%
        opacity: 0
    50%
        opacity: 1
    100%
        opacity: 0

.splash_screen_styles
    position: absolute
    bottom: 40px
    left: 32px
    right: 32px

.splash_content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 32px
    align-self: stretch
    h1
        color: var(--color-white)
        color: #FFF
        font-size: 56px
        font-style: normal
        font-weight: 400
        line-height: 120%

        paint-order: stroke fill
        -webkit-text-stroke: 4px var(--color-black)
        -webkit-background-clip: text
    p
        width: 100%
        max-width: 290px

        color: #FFF
        text-align: center
        font-size: 24px
        font-style: normal
        font-weight: 400
        line-height: 120%

        paint-order: stroke fill
        -webkit-text-stroke: 2px var(--color-black)
        -webkit-background-clip: text
