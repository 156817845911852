// src/components/TaskList/TaskList.module.sass

.task_list
    display: flex
    flex-direction: column
    align-items: center
    align-self: stretch
    gap: 16px
    flex: 1
    padding: 20px
    animation: fade_in_task_list 0.3s ease

@keyframes fade_in_task_list
    0%
        opacity: 0
    100%
        opacity: 1

.header
    display: flex
    justify-content: space-between
    align-items: center
    align-self: stretch
    width: 100%

.heading_tasks
    color: var(--color-white)
    font-size: 20px
    font-style: normal
    font-weight: 400
    line-height: normal

.total_reward
    color: var(--color-gray)
    text-align: right
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal

.tasks
    display: flex
    flex-direction: column
    align-items: center
    align-self: stretch
    gap: 12px
    flex: 1
    .task
        align-self: stretch
        display: flex
        align-items: center
        gap: 16px
        border-radius: 8px
        padding: 8px
        background: var(--color-black)
        border: 1px solid var(--color-grayBorder)
        .icon
            width: 40px
            height: 40px
            border-radius: 50%
            object-fit: cover
            flex: none
        .description
            flex: 1
            color: var(--color-white)
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: normal
        .reward
            color: var(--color-white)
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: normal
